import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Layout from './layout/layout';
import Catalog from "./components/catalog/catalog";
import Product from './components/product/product';
import Vacancies from './components/vacancies/vacancies';
import Applications from './components/applications/applications';
import AddProduct from './components/addProduct/addProduct';
import AddVacancy from './components/addVacancy/addVacancy';
import Vacancy from './components/vacancy/vacancy';
import JobApplications from './components/jobApplications/jobApplications';
import CategoryList from './components/categoryList/categoryList';
import AddCategory from './components/addCategory/addCategory';
import Category from './components/category/category';
import SubcategoryList from './components/subcategoryList/subcategoryList';
import Gallery from './components/gallery/gallery';
import AddPhoto from './components/addPhoto/addPhoto';
import GalleryPhoto from './components/galleryPhoto/galleryPhoto';



function App() {
  const location = useLocation()

  return (
    <Layout>
      <Routes location={location} key={location.pathname}>
        <Route exact path='/' element={<Catalog />} />
        <Route exact path='/product/:id' element={<Product />} />
        <Route exact path='/vacancies' element={<Vacancies />} />
        <Route exact path='/vacancy/:id' element={<Vacancy />} />
        <Route exact path='/applications' element={<Applications />} />
        <Route exact path='/job-applications/:id' element={<JobApplications />} />
        <Route exact path='/add-product' element={<AddProduct />} />
        <Route exact path='/add-vacancy' element={<AddVacancy />} />

        <Route exact path='/category' element={<CategoryList />} />
        <Route exact path='/add-category' element={<AddCategory category={"category"} />} />
        <Route exact path='/category/:id' element={<Category category={"category"} />} />

        {/* <Route exact path='/subcategory' element={< SubcategoryList />} />
        <Route exact path='/add-subcategory' element={<AddCategory category={"subcategory"} />} />
        <Route exact path='/subcategory/:id' element={<Category category={"subcategory"} />} /> */}


        <Route exact path='/gallery' element={<Gallery />} />
        <Route exact path='/add-photo' element={<AddPhoto />} />
        <Route exact path='/gallery/:id' element={<GalleryPhoto />} />

      </Routes>
    </Layout>
  );
}

export default App;
